import { permissions } from '@mpk/shared/domain';

enum ExportAmor3PermissionsEnum {
  None = 0,
  /**
   * Export Articles with AMOR3 API.
   */
  UseExport = 1,
  /**
   * Export Articles and Prices with AMOR3 API.
   */
  UseExportWithPrices = 2 | UseExport,
}

export const ExportAmor3Permissions = permissions(ExportAmor3PermissionsEnum, {
  featureId: 'ExportAmor3',
});
